import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { CodeConsultingSection } from 'views/flow-cadence/code-consulting-section';
import {
    LATEST_RELATED_ARTICLES_SETTINGS,
    PERKS_LIST,
} from 'views/flow-cadence/constants';
import { ContactWithExpertSection } from 'views/flow-cadence/contact-with-expert-section';
import { FlowBusinessBenefitsSection } from 'views/flow-cadence/flow-business-benefits-section';
import { HireDevelopersSection } from 'views/flow-cadence/hire-developers-section';
import { ObjectiveAdvantagesSection } from 'views/flow-cadence/objective-advantages-section';
import { CONSTANTS } from 'constants/styles/constants';
import { FooterSection } from 'modules/footer-section';
import { WorkWithMRSection } from 'modules/technologies/work-with-mr-section';
import { array } from 'prop-types';
import { RealResultsSection } from 'modules/technologies/real-results-section';
import BloctoLogo from 'svgs/technologies/flow/blocto.svg';
import DapperLabsLogo from 'svgs/technologies/flow/dapperlabs.svg';
import NBA2KLogo from 'svgs/technologies/flow/nbatopshot.svg';
import { FormattedMessage } from 'react-intl';
import { Container } from 'components/container';

const SContainer = styled.div`
    padding-top: ${CONSTANTS.MARGIN.PAGE};
`;

const SContainerSummary = styled(Container)`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 2rem;
    margin-top: 5rem;
`;

const Props = {
    faqData: array.isRequired,
};

const POPULAR_APPLICATIONS = [
    {
        logo: <DapperLabsLogo />,
        description:
            'flow-cadence.popularApplications.caseStudies.0.description',
    },
    {
        logo: <NBA2KLogo />,
        description:
            'flow-cadence.popularApplications.caseStudies.1.description',
    },
    {
        logo: <BloctoLogo />,
        description:
            'flow-cadence.popularApplications.caseStudies.2.description',
    },
];

export const FlowCadenceView = ({ faqData }) => {
    const { formatMessage } = useIntl();

    return (
        <SContainer>
            <CodeConsultingSection />
            <ObjectiveAdvantagesSection />
            <RealResultsSection
                title="flow-cadence.popularApplications.title"
                description="flow-cadence.popularApplications.description"
                logos={POPULAR_APPLICATIONS}
            />
            <SContainerSummary>
                <FormattedMessage id="flow-cadence.popularApplications.summary" />
            </SContainerSummary>
            <HireDevelopersSection />
            <FlowBusinessBenefitsSection />
            <WorkWithMRSection
                title="flow-cadence.workWithMobileReality.title"
                firstRowDescriptions={[
                    'flow-cadence.workWithMobileReality.atMobileReality',
                    'flow-cadence.workWithMobileReality.agileDevelopment',
                ]}
                secondRowDescriptions={[
                    'flow-cadence.workWithMobileReality.collaborativeApproach',
                ]}
                perksData={PERKS_LIST}
                firstRowAlt={formatMessage({
                    id: 'flow-cadence.workWithMobileReality.firstRowAlt',
                })}
                secondRowAlt={formatMessage({
                    id: 'flow-cadence.workWithMobileReality.secondRowAlt',
                })}
            />
            <ContactWithExpertSection />
            <FooterSection
                faqSection={{
                    title: faqData.title,
                    data: faqData.data,
                    parseRawAnswer: true,
                }}
                latestRelatedArticles={LATEST_RELATED_ARTICLES_SETTINGS}
            />
        </SContainer>
    );
};

FlowCadenceView.propTypes = Props;
