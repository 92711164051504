export const FLOW_CADENCE_ADVANTAGES = [
    {
        title: 'flow-cadence.enhancedSecurityAndPrivacy',
        description: 'flow-cadence.flowSecurity',
    },
    {
        title: 'flow-cadence.trustAndTransparency',
        description: 'flow-cadence.flowFosters',
    },
    {
        title: 'flow-cadence.decentralizedStructure',
        description: 'flow-cadence.flowArchitectureAllows',
    },
    {
        title: 'flow-cadence.costEfficiency',
        description: 'flow-cadence.byReducingReliance',
    },
    {
        title: 'flow-cadence.speedAndScalability',
        description: 'flow-cadence.flowIsDesigned',
    },
];
