import React from 'react';
import { FlowCadenceView } from 'views/flow-cadence';
import { SEO } from 'components/seo';
import { TAGS } from 'constants/tags';
import messages from 'constants/translations/technologies/flow-cadence.json';
import { graphql } from 'gatsby';
import Layout from 'layouts/standard';
import { object } from 'prop-types';
import { formatFaqData } from 'utilities/contentful';

const FlowCadencePage = ({ location, data }) => {
    const faqData = formatFaqData(data?.allContentfulFaq.nodes[0]);

    console.log(faqData);

    return (
        <Layout location={location} messages={messages}>
            <FlowCadenceView {...{ faqData }} />
        </Layout>
    );
};

FlowCadencePage.propTypes = {
    location: object.isRequired,
};

export default FlowCadencePage;

export const Head = () => <SEO tags={TAGS.CADENCE_FLOW} />;

export const pageQuery = graphql`
    query FlowCadencePageQuery {
        allContentfulFaq(
            filter: {
                slug: { eq: "technologies/hire-flow-blockchain-developers" }
            }
        ) {
            nodes {
                ...FaqFragment
            }
        }
    }
`;
